@tailwind base;
@tailwind components;
@tailwind utilities;

/* .popup-box {
    visibility: hidden;
    opacity: 0; 
  transition: opacity 0.3s ease-in-out; 
  }
  
  .data-grid:hover .popup-box {
    visibility: visible;
    opacity: 1;
  } */

.data-grid:hover{
    cursor: pointer;
}

/* Default state for popup-box (hidden) */
.popup-box {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;
}


@media (min-width: 768px) { 
  .data-grid:hover .popup-box {
    visibility: visible;
    opacity: 1;
  }
}
